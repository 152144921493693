<template>
  <center>
    <template v-if="certifyStatus !== 0">
      <img
        v-if="certifyStatus == 1"
        width="100"
        height="100"
        style="margin-top: 200px"
        src="../image/success.png"
      />
      <img
        v-else
        width="100"
        height="100"
        style="margin-top: 200px"
        src="../image/fail.png"
      />
      <p
        style="
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
          color: #333333;
        "
      >
        {{ result }}
      </p>
      <p
        v-if="certifyStatus == 1"
        style="font-size: 16px; margin-top: 20px; color: #666666"
      >
        {{ duration }}S后自动跳转到电子签入口
      </p>
      <van-button style="width: 200px; margin-top: 20px" @click="toCertify"
        >重新认证</van-button
      >
    </template>
    <template v-else>
      <P style="font-size: 16px; margin-top: 100px; color: #666666"
        >认证中，请等待。</P
      >
    </template>
  </center>
</template>

<script>
import { faceVerifyResult } from "@/api/credit";
export default {
  data() {
    return {
      duration: 6,
      certifyStatus: 0,
      signUrl: "",
      result: "",
      certifyUrl: "",
      orderNo: "",
    };
  },
  mounted() {
    // console.log("=======================", this.$route.query);
    this.orderNo = this.$route.query.orderNo;
    const timer = setInterval(() => {
      this.duration--;
      if (this.duration <= 0) {
        clearInterval(timer);
        this.duration = 6;
        this.faceVerifyResultFun();
      }
    }, 1000);
  },
  methods: {
    faceVerifyResultFun() {
      //   const orderNo = "H202405310197900056";
      faceVerifyResult({ orderNo: this.orderNo }).then((res) => {
        if (res.code !== 10000000) {
          this.$toast(res.msgCode || '系统错误');
          return;
        }
        const { signUrl, certifyStatus, certifyUrl } = res.data;
        this.certifyStatus = certifyStatus;
        this.signUrl = signUrl;
        this.certifyUrl = certifyUrl;
        if (certifyStatus === 1) {
          this.result = "认证成功";
          const timer = setInterval(() => {
            this.duration--;
            if (this.duration <= 0) {
              window.location.href = signUrl;
              clearInterval(timer);
            }
          }, 1000);
        } else if (certifyStatus === 2) {
          this.result = "认证失败";
        } else {
          const timer = setInterval(() => {
            this.duration--;
            if (this.duration <= 0) {
              clearInterval(timer);
              this.duration = 6;
              this.faceVerifyResultFun();
            }
          }, 1000);
        }
      });
    },
    toCertify() {
      window.location.href = this.certifyUrl;
    },
  },
};
</script>

<style></style>
